import React from "react";
import "./portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG5 from "../../assets/portfolio5.jpg";
import IMG6 from "../../assets/portfolio6.jpg";
import IMG7 from "../../assets/portfolio7.jpg";
import IMG8 from "../../assets/portfolio8.jpg";
import IMG9 from "../../assets/portfolio9.jpg";
import IMG10 from "../../assets/portfolio10.jpg";
import IMG11 from "../../assets/portfolio11.jpg";
import IMG12 from "../../assets/portfolio12.jpg";
import IMG13 from "../../assets/portfolio13.jpg";
import IMG14 from "../../assets/portfolio14.jpg";
import IMG15 from "../../assets/portfolio15.jpg";
import IMG16 from "../../assets/portfolio16.jpg";
import IMG17 from "../../assets/portfolio17.jpg";
import IMG18 from "../../assets/portfolio18.jpg";
import { useLanguage } from "../../context/LanguageContext";

const data = [
  {
    id: 9,
    image: IMG16,
    title: "Clinica Lithá",
    demo: "https://clinicalitha.com.br/",
  },
  {
    id: 0,
    image: IMG14,
    title: "Create Your Burger",
    github: "https://github.com/cristianoprogramador/createBurger",
    demo: "https://createburger.com.br/",
  },
  {
    id: 1,
    image: IMG13,
    title: "My Life - Dashboard",
    github: "https://github.com/cristianoprogramador/mylife_dashboard",
    demo: "https://mylife-dashboard.vercel.app/",
  },
  {
    id: 2,
    image: IMG12,
    title: "Learning and Laughing",
    demo: "https://learningandlaughing.com.br/",
  },
  {
    id: 3,
    image: IMG17,
    title: "App-Watch",
    github: "https://github.com/cristianoprogramador/app_watch_frontend",
    demo: "https://appwatch.cristianosilvadev.com/",
  },
  {
    id: 4,
    image: IMG18,
    title: "Better-end",
    github: "https://github.com/cristianoprogramador/better_end_frontend",
    demo: "https://betterend.cristianosilvadev.com/",
  },
  // {
  //   id: 4,
  //   image: IMG3,
  //   title: "Clone do Amazon (Site de vendas)",
  //   github: "https://github.com/cristianoprogramador/amazon-clone",
  //   demo: "https://amazon-clone-7mvejatmn-cristianoprogramador.vercel.app",
  // },
  // {
  //   id: 5,
  //   image: IMG7,
  //   title: "Clone do TikTok",
  //   github: "https://github.com/cristianoprogramador/tiktokclone",
  //   demo: "https://tiktokclone-iota.vercel.app",
  // },
  // {
  //   id: 6,
  //   image: IMG9,
  //   title: "Site de Vendas de Sofá",
  //   github: "https://github.com/cristianoprogramador/SiteVendasSofa",
  //   demo: "https://cristianoprogramador.github.io/SiteVendasSofa/",
  // },
  // {
  //   id: 7,
  //   image: IMG11,
  //   title: "Coffee Shop",
  //   github: "https://github.com/cristianoprogramador/ignite-coffeeshop",
  //   demo: "https://ignite-coffeeshop.vercel.app/",
  // },
  // {
  //   id: 8,
  //   image: IMG6,
  //   title: "Rede Social - Similar ao Facebook",
  //   github: "https://github.com/cristianoprogramador/socialmediawebpage",
  //   demo: "https://cristianoprogramador.github.io/socialmediawebpage",
  // },
];

const Portfolio = () => {
  const { texts } = useLanguage();
  const disableRightClick = (event) => event.preventDefault();

  return (
    <section id="portfolio">
      <h5> {texts?.portfolio?.recentWork}</h5>
      <h2>{texts?.portfolio?.portfolio}</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image" style={{ position: 'relative' }}>
                <img
                  src={image}
                  alt={title}
                  height={270}
                  width={120}
                  style={{ objectFit: "contain", imageRendering: "smooth" }}
                  draggable="false"
                  onContextMenu={disableRightClick}
                />
                <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', zIndex: 1 }}></div>
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                {github ? (
                  <a
                    href={github}
                    className="btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Github
                  </a>
                ) : null}
                {demo ? (
                  <a
                    href={demo}
                    className="btn btn-primary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Live Demo
                  </a>
                ) : null}
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
