import React from 'react';
import './footer.css';
import { FaLinkedin, FaGithub, FaYoutube } from 'react-icons/fa';
import { useLanguage } from '../../context/LanguageContext';

const Footer = () => {
  const { texts } = useLanguage();

  return (
    <footer>
      <a href="#" className='footer__logo'>{texts?.footer?.logo}</a>

      <ul className='permalinks'>
        <li><a href="#">{texts.footer?.links?.home}</a></li>
        <li><a href="#about">{texts.footer?.links?.about}</a></li>
        <li><a href="#experience">{texts.footer?.links?.experience}</a></li>
        <li><a href="#portfolio">{texts.footer?.links?.portfolio}</a></li>
        <li><a href="#testimonials">{texts.footer?.links?.testimonials}</a></li>
        <li><a href="#contact">{texts.footer?.links?.contact}</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/cristiano-pereira-da-silva-bb991a124/" target='_blank' rel="noreferrer"><FaLinkedin size={20} /></a>
        <a href="https://github.com/cristianoprogramador" target='_blank' rel="noreferrer"><FaGithub size={20} /></a>
        <a href="https://www.youtube.com/channel/UCsxEJaQnDvadd2TIBmc58Aw" target='_blank' rel="noreferrer"><FaYoutube size={20} /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; {texts?.footer?.copyright}</small>
      </div>
    </footer>
  );
}

export default Footer;
