import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'
import { useLanguage } from '../../context/LanguageContext'

const Contact = () => {
  const { texts } = useLanguage();

  return (
    <section id='contact'>
      <h5>{texts?.contact?.title}</h5>
      <h2>{texts?.contact?.header}</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon' />
            <h4>{texts?.contact?.email?.label}</h4>
            <h5>{texts?.contact?.email?.address} </h5>
            <a href="mailto:cristiano_own@hotmail.com.br" target="_blank" rel="noreferrer">{texts?.contact?.email?.action}</a>
          </article>
        </div>
      </div>
    </section>
  )
}

export default Contact
