import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('pt');
  const [texts, setTexts] = useState({});

  useEffect(() => {
    import(`../locales/${language}.json`)
      .then(data => {
        setTexts(data);
      })
      .catch(error => console.error("Failed to load language files", error));
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, setLanguage, texts }}>
      {children}
    </LanguageContext.Provider>
  );
};
