import React from "react";
import CV_EN from "../../assets/cv-en.pdf";
import CV_PT from "../../assets/cv-pt.pdf";
import { useLanguage } from "../../context/LanguageContext";

const CTA = () => {
  const { texts, language } = useLanguage();

  console.log(language)

  return (
    <div className="cta" style={{
      marginTop: "10px",
    }}>
      {
        language === "en" ?
          <a href={CV_EN} download className="btn">
            Download CV
          </a> :
          <a href={CV_PT} download className="btn">
            Download CV
          </a>
      }
      <a href="#contact" className="btn btn-primary">
        {texts?.about?.contactButton}
      </a>
    </div>
  );
};

export default CTA;
