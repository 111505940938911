import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me2.png'
import FLAGBRAZIL from '../../assets/flagbrazil.svg'
import FLAGEUA from '../../assets/flagEUA.svg'
import HeaderSocial from './HeaderSocials'
import { useLanguage } from '../../context/LanguageContext'

const Header = () => {
  const { setLanguage, texts } = useLanguage();

  return (
    <header>
      <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'row', gap: 5 }}>
        <img src={FLAGBRAZIL} alt="PT" style={{ width: '40px', height: '20px', cursor: 'pointer' }} onClick={() => setLanguage('pt')} />
        <img src={FLAGEUA} alt="EN" style={{ width: '40px', height: '20px', cursor: 'pointer' }} onClick={() => setLanguage('en')} />
      </div>
      <div className="container header__container">
        <h5>{texts.header?.greeting}</h5>
        <h1>{texts.header?.name}</h1>
        <h5 className="text-light">{texts.header?.profession}</h5>
        <CTA />
        <HeaderSocial />
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <a href="#contact" className='scroll__down'>{texts.header?.scrolling}</a>
      </div>
    </header>
  )
}

export default Header
