import React from 'react'
import './about.css'
import ME from '../../assets/me1.jpeg'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'
import { useLanguage } from '../../context/LanguageContext'


const About = () => {
  const { texts } = useLanguage();

  return (
    <section id='about'>
      <h5>{texts?.about?.title}</h5>
      <h2>{texts?.about?.header}</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Minha foto" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>{texts?.about?.experience?.label}</h5>
              <small>{texts?.about?.experience?.details}</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>{texts?.about?.sectors?.label}</h5>
              <small>{texts?.about?.sectors?.details}</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>{texts?.about?.education?.label}</h5>
              <small>{texts?.about?.education?.details}</small>
            </article>
          </div>

          <p className='flex flex-wrap'>
            {texts?.about?.aboutMe}
          </p>

          <a href="#contact" className="btn btn-primary">{texts?.about?.contactButton}</a>
        </div>
      </div>
    </section>
  )
}

export default About
